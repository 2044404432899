import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'sweetalert2/src/sweetalert2.scss'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ConfirmEmail = React.lazy(() => import('./views/pages/confirmEmail'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={ <Navigate to='/login'/> } />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/resetpassword" name="Reset Password Page" element={<ResetPassword/>} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/confirm" name="Confirm Email" element={<ConfirmEmail/>}/>
            <Route path="*" element={<Navigate to='404' />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
